<template>
	<v-container fluid>
		<LoginForm color="transparent" :dark="false" />
	</v-container>
</template>

<script>
export default {
	metaInfo() {
		return {
			title: this.$t('auth.login'),
		}
	},
	components: {
		LoginForm: () => import('@/components/auth/LoginForm.vue'),
	},
}
</script>
